// skuCodes字符串转为数组
export function skuTurnArr (params) {
  if (!Array.isArray(params.skuCodes)) {
    if (params.skuCodes.length === 0) {
      params.skuCodes = []
    } else {
      if (params.skuCodes.indexOf(',') > 0) {
        params.skuCodes = params.skuCodes.split(',')
      } else if (params.skuCodes.indexOf('，') > 0) {
        params.skuCodes = params.skuCodes.split('，')
      } else {
        params.skuCodes = [params.skuCodes]
      }
    }
  }
}

export function mapState (val) {
  let state
  switch (val) {
    case 0:
      state = '待审核'
      break
    case 2:
      state = '成功'
      break
    case -1:
      state = '失败'
      break
    case 1:
      state = '审核中'
      break
    case -2:
      state = '处理中'
      break
    case 3:
      state = '已生效'
      break
    case 4:
      state = '已撤销'
      break
    case 5:
      state = '已完成'
      break
    case 6:
      state = '已删除'
      break
  }
  return state
}

export function mapIdentifyStatus (val) {
  let state
  switch (val) {
    case 'UN_IDENTIFY':
      state = '待审核'
      break
    case 'IDENTIFY_SUCCESS':
      state = '审核通过'
      break
    case 'IDENTIFY_FAIL':
      state = '审核驳回'
      break
    case 'IDENTIFY_ING':
      state = '审核中'
      break
  }
  return state
}

// 匹配 接口：param/字典：dictTypeKey 返回的数据
export function mapText (model, seletOption) {

  let arr = seletOption.filter((x) => x.value == model)
  let str = arr.length > 0 && arr[0].label
  var data = new Map();
  seletOption.forEach((item) => {
    if (item.value == model) {
      data.set('label', item.label)
    }
  })
  // console.log(data);
  return data.get('label') || str || ''
}
//
export function mapFilterDictionary (dictionarySelet, model, param) {
  let arr = []
  dictionarySelet.forEach((item) => {
    if (item.param === param) {
      // console.log('未查到', item, model, param)
      arr = item.list.filter((x) => x.value == model)
    }
  })
  let str = arr.length > 0 && arr[0].label
  return str || '未查到'
}

// 根据城市id查找城市名
export function findCity (cityList, areaId) {
  let cityArr = []
  let str
  cityList.map((item) => {
    if (item.children) {
      cityArr.push(item.children)
    }
  })
  let citys = cityArr.flat()
  citys.forEach((item) => {
    if (item.areaId == areaId) {
      str = item.name
    }
  })
  return str
}


// 金额显示.00格式
export function NumFormat (value) {
  if (!value) return '0.00'
  value = value.toFixed(2)
  var intPart = Math.trunc(value) // 获取整数部分
  var intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') // 将整数部分逢三一断
  var floatPart = '.00' // 预定义小数部分
  var value2Array = value.split('.')
  // =2表示数据有小数位
  if (value2Array.length === 2) {
    floatPart = value2Array[1].toString() // 拿到小数部分
    if (floatPart.length === 1) {
      return intPartFormat + '.' + floatPart + '0'
    } else {
      return intPartFormat + '.' + floatPart
    }
  } else {
    return intPartFormat + floatPart
  }
}
export function getFloatStr (num) {
  num += "";
  num = num.replace(/[^0-9|\.]/g, ""); //清除字符串中的非数字非.字符

  if (/^0+/)
    //清除字符串开头的0
    num = num.replace(/^0+/, "");
  if (!/\./.test(num))
    //为整数字符串在末尾添加.00
    num += ".0";
  if (/^\./.test(num))
    //字符以.开头时,在开头添加0
    num = "0" + num;
  num += "0"; //在字符串末尾补零
  num = num.match(/\d+\.\d{1}/)[0];
  return num;
}

export function getArrSort (data, name) {
  console.log(data, name);
  return data.sort(function (a, b) { return a[name].localeCompare(b[name], 'zh-CN') })


  // data.sort((a, b) => {
  //   console.log(a[name]);
  //   let reg = /^[A-z]/;
  //   if (reg.test(a[name]) || reg.test(b[name])) {
  //     if (a[name] > b[name]) {
  //       return 1;
  //     } else if (a[name] < b[name]) {
  //       return -1;
  //     } else {
  //       return 0;
  //     }
  //   } else {
  //     return a[name].localeCompare(b[name], "zh");
  //   }
  // });

  // recursion (data) {
  //   data.map(e => {
  //     if (e.children) {
  //       e.children.sort(function (a, b) { return a.name.localeCompare(b.name, 'zh-CN') })
  //       this.recursion(e.children)
  //     }
  //   })
  //   return data
  // },
}